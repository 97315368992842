<template>
    <ContentLayout>
        <v-row>
            <v-col cols="12" md="6" lg="7" xl="8">
                <v-form ref="form" @submit.prevent @submit="submit">
                    <v-card elevation="8">
                        <v-card-title class="eurostile-font primary--text"><h2>{{ $t("pages.contact.message") }}</h2></v-card-title>
                        <v-divider class="mx-4"></v-divider>
                        <v-card-text class="text-body-1">
                            <v-row dense>
                                <v-col cols="12">
                                    <v-text-field
                                        :label="$t('pages.contact.form.enterprise_name')"
                                        v-model="form.enterprise_name"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="30" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        :label="$t('pages.contact.form.first_name')"
                                        v-model="form.first_name"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="30" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        :label="$t('pages.contact.form.last_name')"
                                        v-model="form.last_name"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="30" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        :label="$t('pages.contact.email')"
                                        v-model="form.email"
                                        outlined
                                        :rules="[ $data.customRules.required, $data.customRules.email ]" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        :label="$t('pages.contact.form.phone')"
                                        v-model="form.phone"
                                        v-mask="'(###) ###-####'"
                                        outlined
                                        :rules="[ $data.customRules.required ]" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        :label="$t('pages.contact.form.message')"
                                        v-model="form.message"
                                        outlined counter="500"
                                        :rules="[ $data.customRules.required ]" maxlength="500" :disabled="submitting"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" large text type="submit" :loading="submitting">
                                <v-icon left>mdi-send</v-icon>{{ $t('pages.contact.submit.btn') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
            <v-col cols="12" md="6" lg="5" xl="4">
                <v-card elevation="8">
                    <v-card-title class="eurostile-font primary--text"><h2>{{ $t("pages.contact.contact-us") }}</h2></v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-text class="text-body-1">
                        <v-list two-line>
                            <v-list-item :href="'tel:' + _env.VUE_APP_PHONE" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-clock
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t("pages.contact.opening-hours.title") }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("pages.contact.opening-hours.subtitle") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :href="'tel:' + _env.VUE_APP_PHONE" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-phone
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ _env.VUE_APP_PHONE }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("pages.contact.phone") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :href="'mailto:' + _env.VUE_APP_EMAIL" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-email
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ _env.VUE_APP_EMAIL }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("pages.contact.email") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    export default {
        name: "contact-view",
        components: { ContentLayout },
        data: () => ({
            submitting: false,
            form: {
                enterprise_name: null,
                first_name: null,
                last_name: null,
                phone: null,
                email: null,
                message: null
            }
        }),
        methods: {
            submit() {
                if(this.submitting) {
                    return;
                }
                if(!this.$refs || !this.$refs.form || !this.$refs.form.validate()) {
                    this.$root.snack.show({
                        text: this.$i18n.t("form-validation-error"),
                        icon: "mdi-alert-circle",
                        color: "error"
                    });
                    return;
                }

                this.submitting = true;
                this._request("forms/contact", this.form).then(response => {
                    if(response.error) {
                        this.$root.snack.show({
                            text: this.$i18n.t("pages.contact.submit.error"),
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                        return;
                    }
                    
                    this.form.message = null;
                    this.$refs.form.resetValidation();
                    
                    this.$root.snack.show({
                        text: this.$i18n.t("pages.contact.submit.success"),
                        icon: "mdi-check",
                        color: "success"
                    });

                }).finally(() => {
                    this.submitting = false;
                });
            }
        }
    };
</script>

<style scoped>
    #map-wrapper {
        position: relative;
    }
    #map {
        z-index: 2;
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
    }
    #map-loading {
        z-index: 1;
    }
</style>