<template>
    <v-app>
        <v-main>
            <v-navigation-drawer v-model="drawer" app right temporary>
                <!--<div class="background-absolute"></div>-->
                <v-img src="@/assets/img/logo-vertical.svg" height="120px" contain class="ma-4"></v-img>
                <v-divider></v-divider>
                <v-list dense nav>
                    <v-list-item v-for="route in drawerRoutes" :key="route.name" link :to="route.name" active-class="primary darken-1 white--text">
                        <v-list-item-icon>
                            <v-icon>{{ route.meta.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-button">{{ $t("pages." + route.name + ".title") }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <!--<template v-slot:append>
                    <div id="actions" class="pa-2">
                        <v-btn block outlined small color="primary" @click="$root.dialogs.login.open()" v-if="!$store.state.user">
                            <v-icon left>mdi-login</v-icon>{{ $t("session.login.text") }}
                        </v-btn>
                        <v-btn block outlined small @click="$root.dialogs.login.logout()" v-else>
                            <v-icon left>mdi-logout</v-icon>{{ $t("session.logout.text") }}
                        </v-btn>
                    </div>
                </template>-->
            </v-navigation-drawer>
            <v-app-bar app color="white" height="120px">
                <v-app-bar-title>
                    <router-link to="/">
                        <v-img src="@/assets/img/logo-horizontal.svg" height="auto" width="300px" contain></v-img>
                    </router-link>
                </v-app-bar-title>
                <v-spacer></v-spacer>
                <div v-if="appbarRoutes.length > 0">
                    <v-btn
                        v-for="route in appbarRoutes"
                        text large color="primary"
                        :key="route.name" :to="route.name"
                        active-class="primary text--lighten-5"
                        class="mr-4 text-button"
                    >
                        <v-icon v-if="!!route.meta && !!route.meta.icon" left size="22">{{ route.meta.icon }}</v-icon>
                        {{ $t("pages." + route.name + ".title") }}
                    </v-btn>
                </div>
                <v-btn
                    v-if="$vuetify.breakpoint.lgAndUp"
                    outlined large color="primary"
                    active-class="primary text--lighten-5"
                    class="mr-4 text-button"
                    :href="'tel:' + _env.VUE_APP_PHONE" target="_blank"
                >
                    <v-icon left size="22">mdi-phone</v-icon>
                    {{ $t("call-us") }} <span class="accent--text ml-1 font-weight-black">{{ _env.VUE_APP_PHONE }}</span>
                </v-btn>
                <LanguageElement v-if="$vuetify.breakpoint.smAndUp"></LanguageElement>
                <v-app-bar-nav-icon @click="drawer = !drawer" class="primary--text"></v-app-bar-nav-icon>
            </v-app-bar>
            <router-view></router-view>
        </v-main>
        <v-footer dark padless inset>
            <v-card class="flex" flat tile>
                <v-card-title class="primary">
                    <v-row no-gutters>
                        <v-col cols="12" class="mb-4" v-if="$vuetify.breakpoint.xs">
                            <LanguageElement block></LanguageElement>
                        </v-col>
                        <v-col cols="12" md="8" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                            <h3 class="subheading eurostile-font">{{ $t("social-network") }}</h3>
                        </v-col>
                        <v-col cols="12" md="4" :class="{ 'text-center': $vuetify.breakpoint.smAndDown, 'text-right': $vuetify.breakpoint.mdAndUp }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-on="on" v-bind="attrs"
                                        class="mx-4" dark icon
                                        :href="_env.VUE_APP_SOCIAL_LINKEDIN" target="_blank"
                                    >
                                        <v-icon size="26px">mdi-linkedin</v-icon>
                                    </v-btn>
                                </template>
                                LinkedIn
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="py-2 primary darken-2 white--text text-center">
                    &copy; {{ new Date().getFullYear() }} {{ _env.VUE_APP_TITLE }} — {{ $t("copyright") }}
                </v-card-text>
            </v-card>
        </v-footer>
        <!--<LoginDialog ref="loginDialog"></LoginDialog>-->
        <SnackElement ref="snackElement"></SnackElement>
    </v-app>
</template>

<script>
    //import LoginDialog from "./dialogs/Login";
    import SnackElement from "./elements/Snack";
    import LanguageElement from "./elements/Language";

    export default {
        name: "app",
        components: { SnackElement, LanguageElement }, // LoginDialog
        data: () => ({
            drawer: false,
            login: false
        }),
        watch: {
            "$route": function() {
                //console.log("$route watch");
                window.scrollTo(0, 0);
            }
        },
        computed: {
            appbarRoutes() {
                return this.$router.options.routes.filter(route => !!route.meta && !!route.meta.breakpoints && route.meta.breakpoints.findIndex(b => b === this.$vuetify.breakpoint.name) === -1);
            },
            drawerRoutes() {
                return this.$router.options.routes.filter(route => !!route.meta);
                //return this.$router.options.routes.filter(route => !!route.meta && (!route.meta.breakpoints || (!!route.meta.breakpoints && route.meta.breakpoints.findIndex(b => b === this.$vuetify.breakpoint.name) !== -1)));
            }
        },
        mounted() {
            this.$root.dialogs = {
                login: this.$refs.loginDialog
            };
            this.$root.snack = this.$refs.snackElement;
        }
    };
</script>

<style>
    @font-face {
        font-family: "eurostile";
        src: 
        url("@/assets/fonts/eurostile-regular.ttf") format("truetype"), 
        url("@/assets/fonts/eurostile-regular.woff2") format("woff2");
    }
    .eurostile-font {
        font-family: "eurostile" !important;
    }

    .background-absolute {
        z-index: 0;
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
    }
    .background {
        position: relative;
    }
    .background::after, .background-absolute::after {
        z-index: 1;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 0.5;

        background: url("@/assets/img/train-1.jpg");
        background-color: #000;
        background-position: center -150px;
    }
    .background::before, .background-absolute::before {
        z-index: 2;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 0.8;

        background: linear-gradient(180deg, rgba(0,0,0,0) 60%, #000 100%);
    }
    .background > *, .background-absolute > * {
        z-index: 3;
    }
</style>