<template>
    <ContentLayout>
        <p class="text-center text-h5 primary--text" :class="{ 'pa-4': $vuetify.breakpoint.xs }">
            <v-icon large color="primary" left>mdi-train</v-icon>{{ $t("pages.services.text-1") }}
        </p>
        <v-row no-gutters justify="center">
            <v-col cols="12" md="10">
                <v-card color="primary darken-2" dark :class="{ 'mt-8': $vuetify.breakpoint.smAndUp, 'mt-4': $vuetify.breakpoint.xs }">
                    <v-row no-gutters>
                        <v-col cols="12" sm="5" md="6">
                            <v-img src="@/assets/img/train-2.jpg" height="100%"></v-img>
                        </v-col>
                        <v-col cols="12" sm="7" md="6">
                            <v-card-title>{{ $t("pages.services.primary-title") }}</v-card-title>
                            <v-list two-line subheader color="transparent">
                                <v-list-item v-for="(_, i) in 3" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title class="wrap-text">{{ $t("pages.services.primary-items[" + i + "].title") }}</v-list-item-title>
                                        <v-list-item-subtitle class="wrap-text">{{ $t("pages.services.primary-items[" + i + "].description") }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <div class="ma-4">
                                <v-btn outlined :block="$vuetify.breakpoint.xs" :to="{ name: 'contact' }">
                                    <v-icon left>mdi-message-text-outline</v-icon>{{ $t("contact-us") }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card color="grey lighten-4" :class="{ 'my-8': $vuetify.breakpoint.smAndUp, 'my-4': $vuetify.breakpoint.xs, 'text-right': $vuetify.breakpoint.smAndUp }">
                    <v-row no-gutters>
                        <v-col cols="12" sm="7" md="6">
                            <v-card-title :class="{ 'justify-end': $vuetify.breakpoint.smAndUp }">{{ $t("pages.services.secondary-title") }}</v-card-title>
                            <v-list two-line subheader color="transparent">
                                <v-list-item v-for="(_, i) in 4" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title class="wrap-text">{{ $t("pages.services.secondary-items[" + i + "].title") }}</v-list-item-title>
                                        <v-list-item-subtitle class="wrap-text">{{ $t("pages.services.secondary-items[" + i + "].description") }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <div class="ma-4">
                                <v-btn outlined :block="$vuetify.breakpoint.xs" :to="{ name: 'contact' }">
                                    <v-icon left>mdi-message-text-outline</v-icon>{{ $t("contact-us") }}
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="5" md="6">
                            <v-img src="@/assets/img/train-3.jpg" height="100%"></v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <p class="text-center text-h6 primary--text" :class="{ 'pa-4': $vuetify.breakpoint.xs }">
            <v-icon large color="primary" left>mdi-certificate</v-icon>{{ $t("pages.services.text-2") }}
        </p>
        <v-divider></v-divider>
        <div class="text-justify text-caption mt-4" :class="{ 'pa-4': $vuetify.breakpoint.xs }">
            <v-icon small left>mdi-alert-outline</v-icon>{{ $t("pages.services.text-3") }}
        </div>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    export default {
        name: "services-view",
        components: { ContentLayout },
        data: () => ({
            
        })
    };
</script>

<style scoped>
    .wrap-text {
        white-space: normal;
    }
</style>