import HomeView from "./views/Home";
import ServicesView from "./views/Services";
import ContactView from "./views/Contact";

export default [
    {
        path: "/home",name: "home", component: HomeView,
        meta: {
            icon: "mdi-home-outline",
            breakpoints: [ "xs", "sm" ]
        }
    },
    {
        path: "/services", name: "services", component: ServicesView,
        meta: {
            icon: "mdi-account-group-outline",
            breakpoints: [ "xs" ]
        }
    },
    {
        path: "/contact", name: "contact", component: ContactView,
        meta: {
            icon: "mdi-message-text-outline",
            breakpoints: [ "xs", "sm", "md" ]
        }
    },

    { path: "*", redirect: "/home" },
];