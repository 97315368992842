<template>
    <div>
        <v-sheet color="primary" height="240px" dark>
            <v-row no-gutters class="fill-height background" align="center" justify="center">
                <v-col cols="auto" class="text-center" :class="{ 'text-h2': $vuetify.breakpoint.smAndUp, 'text-h3': $vuetify.breakpoint.xs }">
                    <!--<v-icon v-if="!!$route.meta && !!$route.meta.icon" size="50" class="mr-4">{{ $route.meta.icon }}</v-icon>-->
                    <span class="eurostile-font">{{ $t("pages." + $route.name + ".title") }}</span>
                </v-col>
            </v-row>
        </v-sheet>
        <v-container fluid :class="{ 'pa-8': $vuetify.breakpoint.smAndUp }">
            <slot></slot>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "content-layout",
        props: {}
    };
</script>
