<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-on="on" v-bind="attrs"
                :text="!block" :block="block" :color="block ? 'accent' : 'secondary'"
                large class="mr-4 eurostile-font text-button"
                @click="$i18n.locale = $i18n.locale == 'fr' ? 'en' : 'fr'"
            >
                <v-icon left>mdi-web</v-icon>{{ $i18n.locale == "fr" ? "en" : "fr" }}
            </v-btn>
        </template>
        {{ $t("change-language") }}
    </v-tooltip>
</template>

<script>
    export default {
        name: "language-element",
        props: {
            block: {
                type: Boolean,
                default: false
            }
        }
    };
</script>