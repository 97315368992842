<template>
    <v-container fluid class="pa-0" id="container">
        <v-carousel hide-delimiter-background show-arrows-on-hover cycle height="100%">
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
                <v-sheet :color="slide.color" height="100%" dark>
                    <v-row no-gutters class="fill-height background" align="center" justify="center">
                        <v-col cols="auto text-center">
                            <div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }">
                                <span class="eurostile-font font-weight-bold text-uppercase">{{ $t("pages.home.slides[" + i + "].title") }}</span>
                            </div>
                            <div :class="{ 'text-h4': $vuetify.breakpoint.mdAndUp, 'text-h5': $vuetify.breakpoint.smAndDown }">
                                <span class="eurostile-font">{{ $t("pages.home.slides[" + i + "].subtitle") }}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </v-container>
</template>

<script>
    export default {
        name: "home-view",
        data: () => ({
            slides: [
                {
                    color: "primary darken-2"
                },
                {
                    color: "primary darken-4"
                }
            ]
        })
    };
</script>

<style scoped>
    #container {
        position: relative;
        height: 100%;
    }
</style>