import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import i18n from './i18n';
import en from 'vuetify/src/locale/en.ts';
import fr from 'vuetify/src/locale/fr.ts';

Vue.use(Vuetify);

console.log("navigator.language", navigator.language);

export default new Vuetify({
    lang: {
        locales: { en, fr },
        current: navigator.language.substring(0, 2),
    },
    theme: {
        themes: {
            light: {
                primary: '#009eeb', // logo blue
                secondary: '#000000',
                accent: '#0077b3',
            },
        },
    },
});
